const handlers = {};
const lastEventCache = {}; // map of last data emitted for each event (for greedy event handlers)

const off = (evtName, callback) => {
	handlers[evtName] = handlers[evtName] || []; // ensure we have an array
	if (callback == null) {
		// off('evt') deregisters all callbacks
		handlers[evtName] = [];
	} else {
		// off('evt', cb) deregisters a specific callback
		handlers[evtName] = handlers[evtName].filter(cb => cb !== callback);
	}
};

export const on = (evtName, callback, config = {}) => {
	handlers[evtName] = handlers[evtName] || []; // ensure we have an array
	// save once state to handler
	callback.$once = !!config.once;
	// register a callback with this event
	handlers[evtName].push(callback);
	// apply the last args fired if greedy
	if (!!config.greedy && lastEventCache[evtName]) {
		callback.apply(null, lastEventCache[evtName]);
	}
	// return a deregistration function
	return () => off(evtName, callback);
};

export const once = (evtName, callback, config = {}) =>
	on(evtName, callback, Object.assign({}, config, {once: true}));

export const fire = (evtName, ...args) => {
	handlers[evtName] = handlers[evtName] || []; // ensure we have an array
	// cache the args (data) for this call
	lastEventCache[evtName] = args;
	// fire all callbacks registered with this evt
	handlers[evtName].forEach(cb => {
		cb.apply(null, args);
		// deregister once-fired handler
		if (cb.$once) {
			off(evtName, cb);
		}
	});
};


// // USAGE
// console.clear();
// const deregister = on('some-event', data => console.log(data));
// fire('some-event', {some: 'data'});
// deregister();
// fire('some-event', {some: 'data2'});
