export default function (manifestV1, uiModuleAssets) {
	const {moduleBasePath} = window.buildConfig;
	const uiModPrefix = 'assets.uiModules.';
	return Object.assign(
		{},
		// sets up manifest data for ui-modules that have overrides but aren't
		// officially setup in Jarvis yet.
		Object.keys(localStorage)
			.filter(k => k.startsWith(uiModPrefix))
			.map(k => k.slice(uiModPrefix.length))
			.reduce((acc, k) => Object.assign(acc, {[k]: {notRegistered: true}}), {}),
		// mutate manifestV1 into manifestV2
		Object.keys(manifestV1).reduce((acc, modKey) => {
			const mData = manifestV1[modKey];
			return Object.assign(acc, {
				[modKey]: {
					path: {
						app: `${moduleBasePath}/${mData.location}app.js`,
						css: `${moduleBasePath}/${mData.location}app.css`,
						routeData: `${moduleBasePath}/${mData.location}route-data.js`,
					},
					config: mData.config
				}
			});
		}, {}),
		// construct manifestV2 data from Zeit locations data
		Object.keys(uiModuleAssets).reduce((acc, modName) => {
			const modBaseUrl = uiModuleAssets[modName];
			return Object.assign(acc, {
				[modName]: {
					path: {
						app: `${modBaseUrl}/app.js`,
						css: `${modBaseUrl}/app.css`,
						routeData: `${modBaseUrl}/route-data.js`,
						config: `${modBaseUrl}/ui-module-config.json`,
					}
				}
			});
		}, {})
	);
};
