import fetch from 'unfetch';
import { loadUiModuleAssets, loadEnvConfig, loadAsset } from './utils/asset-loader';
import { getFluidImports } from './utils/fluid-imports';
import * as assets from './utils/asset-url-service';
import * as eventBus from './utils/event-bus';
import makeManifestData from './utils/manifest-data-constructor';
import { getUiModDetails } from './utils/ui-module-helper';
import { showOverrideFooter } from './utils/override-footer';
import { applyOverrideState } from './utils/override-state';
import handleSetAssetUrl from './utils/set-asset-url-handler';
import { getQueryParamValue, removeQueryParam } from './utils/location-helpers';
import './state-handlers/404';
import { ENV_VAR, ERROR_MSG, ENV_URL } from './utils/constants';
import { _warn, _error, _log, isOfType } from './utils/helpers';

const overrideState = {};
const overrideFooterState = { env: null, assets: {}, buildConfig: null };

// create a function to remove an override
const createAlterFunc = (key, subject = 'OVERRIDE') => ({
	'⚙️  SETTINGS ⚙️': '',
	get [`REMOVE_${subject}`]() {
		window.localStorage.removeItem(key);
		window.location.reload();
		return '...REMOVED!';
	}
});

// load the sidekick
const sidekickPromise = Promise.all([
	loadAsset('css', `${assets.tools.sidekick}/sidekick.css`),
	loadAsset('js', `${assets.tools.sidekick}/sidekick.js`)
]).then(() => {
	window.sidekick.init({
		addOpenSans: false,
		addSidekickCss: false,
		cleanCache: (/^\/select\/?$/i).test(window.location.pathname)
	});
});

// handle any asset overrides
handleSetAssetUrl();

// Get the environment name from the query string
const envConfig = getQueryParamValue('envConfig');
if (envConfig != null) {
	window.localStorage.setItem(ENV_VAR.DEFAULT, envConfig);
	removeQueryParam('envConfig');
}

// Get the current environment name from localStorage (if previously set)
let envName = window.localStorage.getItem(ENV_VAR.DEFAULT);
// Check if the domain is a production environment
const isProductionDomain = window.location.host.endsWith('.frontlineeducation.com');
// Check if the environment is "Morpheus" based on the process environment variable
const isMorpheus = process.env.IDP === ENV_VAR.MORPHEUS;
// Get the environment name from the process (if available)
const envNameFromProcess = process.env.ENV_NAME;

// If the environment name is not set, determine the environment based on the domain
if (envName === null) {
	// If the domain is a production environment and not Morpheus, set the environment to "production"
    if (isProductionDomain && !isMorpheus) {
        envName = ENV_VAR.PRODUCTION;
    } else {
		// Determine the environment based on the host and pathname
        if (window.location.host.startsWith(ENV_URL.SUPERSUITAWSQA)) {
            envName = window.location.pathname.startsWith('/pd-') ? ENV_VAR.PG_QA : ENV_VAR.FC_QA;
        } else if (window.location.host.startsWith(ENV_URL.SUPERSUITAWSDEV)) {
            envName = ENV_VAR.FC_DEV;
        } else {
            envName = ENV_VAR.STAGE;
        }
    }
}

// If the environment is Morpheus and the process environment name is set, use it
if (isMorpheus && envNameFromProcess != null) {
    envName = envNameFromProcess;
}

// Update local storage if the environment name has changed
if (envName && envName !== window.localStorage.getItem(ENV_VAR.DEFAULT)) {
    window.localStorage.setItem(ENV_VAR.DEFAULT, envName);
}

// If the environment name is set, override the environment
if (envName != null && envName !== ENV_VAR.PRODUCTION) {
	overrideFooterState.env = envName;
	overrideState.env = envName;
	const isUrlRe = /^(https?:|\/\/)/;
	const configFile = process.env.IDP !== ENV_VAR.MORPHEUS ? envName : `morpheus-${envName}`;
	const envConfigUrl = (isUrlRe.test(envName) ? envName : `${process.env.BASE_URL}/env-configs/${configFile}.json`);
	// fetch the environment config file
	fetch(envConfigUrl)
		.then(res => res.json())
		.then(envCfg => window.buildConfig = envCfg)
		.then(() => {
			_warn(`ENVIRONMENT CONFIG: ${envName}`);
			goBootstrap();
		})
		.catch(err => {
			if (err.message === ERROR_MSG.UNEXP_TOKEN) {
				_error(`MISSING ENVIRONMENT CONFIG: "${envName}" `, createAlterFunc(ENV_VAR.DEFAULT, 'ENVIRONMENT'));
			} else {
				_error(ERROR_MSG.ENV_ERROR, err);
			}
		});
} else {
	// no environment override, proceed with default buildConfig
	goBootstrap();
}

function goBootstrap() {
	// store a copy of the original buildConfig
	window.buildConfigOrig = JSON.parse(JSON.stringify(window.buildConfig));

	const buildConfigOverrides = Object.keys(window.localStorage)
		.filter(k => k.startsWith('buildConfig.'));
	overrideFooterState.buildConfig = buildConfigOverrides.length;
	buildConfigOverrides.forEach(envKey => {
		const attrPath = envKey.substr(12).split('.');
		const attrValue = window.localStorage.getItem(envKey);
		overrideState[envKey] = attrValue;
		const overrideResult = attrPath.reduce((obj, key) => {
			if (obj == null) { return null; }
			if (isOfType(obj[key], 'object')) {
				return obj[key];
			} else if (obj[key] != null) {
				obj[key] = attrValue;
				return true;
			} else {
				return null;
			}
		}, window.buildConfig);
		if (overrideResult == null || isOfType(overrideResult, 'object')) {
			_error('INVALID OVERRIDE:', `${envKey} = "${attrValue}"`);
		} else {
			_warn(`${envKey.split('.').join(' 〉')} 〉"${attrValue}" `, createAlterFunc(envKey));
		}
	});
	window.buildConfigData = JSON.parse(JSON.stringify(window.buildConfig));

	const { moduleBasePath } = window.buildConfig;

	// define SuperSuit global
	window.ss = window.ss || {};
	window.ss.env = Object.assign({}, assets);
	window.ss.events = eventBus;

	// notify developer via console about overridden asset locations
	Object.keys(window.localStorage)
		.filter(k => k.startsWith('assets.'))
		.forEach(envKey => {
			const [assetType, assetKey] = envKey.substr(7).split('.');
			const assetValue = window.localStorage.getItem(envKey);
			_warn(`${assetType} 〉${assetKey} 〉"${assetValue}" `, createAlterFunc(envKey));
			overrideFooterState.assets[assetType] = overrideFooterState.assets[assetType] || 0;
			overrideFooterState.assets[assetType] += 1;
			overrideState[envKey] = assetValue;
		});

	// show the override footer
	showOverrideFooter(overrideFooterState);
	let focused = true;
	window.addEventListener('focus', () => { focused = true; applyOverrideState(overrideState); });
	window.addEventListener('blur', () => focused = false);
	window.addEventListener('contextmenu', () => (!focused && applyOverrideState(overrideState)));
	const killPreLoader = () => window.document.getElementById('pre-loader').style.display = 'none';
	window.ss.util = { killPreLoader, getFluidImports, loadEnvConfig };

	// load the sidekick and manifest
	Promise.all([fetch(`${moduleBasePath}/manifest.json`), sidekickPromise])
		.then(([res]) => res.json())
		.then(manifestV1 => makeManifestData(manifestV1, assets.uiModules))
		.then(manifest => {
			window.ss.env.manifest = manifest;
			const mod = getUiModDetails();

			// important auto-redirects
			const loc = window.location;
			const isMorpheusEnv = process.env.IDP === ENV_VAR.MORPHEUS && loc.pathname.endsWith(mod.urlValue);
			const isMatchingPath = loc.pathname === `/${mod.urlValue}` && !loc.pathname.includes('select') && !loc.pathname.includes('auth_callback');
			// default url should be /select/
			// When there is no url then we need this to go to the org switcher and load main.js
			if (mod.urlValue === '') {
				// if the url is empty, redirect to /select/
				loc.pathname = '/select/';
			} else if (isMorpheusEnv || isMatchingPath) {
				// if the url is the same as the module, add a trailing
				loc.pathname += '/';
			} else {
				if (!mod.isValid) {
					_warn(`Module [${mod.urlValue}] not found`);
				} else {
					_log(`Hooking up module ${mod.bootstrapName}`);
				}
				return loadUiModuleAssets(mod);
			}
		})
		.then(() => {
			// listen for route changes
			eventBus.on('route-change', (to, from) => {
				const toMod = getUiModDetails(to);
				const fromMod = getUiModDetails(from);
				if (toMod.bootstrapName !== fromMod.bootstrapName) {
					eventBus.fire('ui-module-deactivate', fromMod);
					loadUiModuleAssets(toMod);
				}
			});
		})
		.catch((e) => {
			_error(ERROR_MSG.APP_ERROR);
		});

	// listen for the magic key combo to reload the environment config
	window.document.addEventListener('keypress', evt => {
		const { keyCode, which, ctrlKey, shiftKey } = evt;
		const isActionKey = (which === 5 || keyCode === 5);
		const hasModifiers = (ctrlKey && shiftKey);
		if (isActionKey && hasModifiers) { loadEnvConfig(); }
	}, { once: true });

	// fluid-components snooty hookup
	if (window.location.hostname === 'localhost') {
		window.sessionStorage.setItem('snooty', `${process.env.BASE_URL}/fluid-components/snooty.min.js`);
	}
}
