import {getQueryParamValue, removeQueryParam} from './location-helpers';

export default function handleSetAssetUrl () {
	const assetValue = getQueryParamValue('setAsset');

	if (assetValue != null) {
		const [key, value] = assetValue.split('|>');
		const hasKeyAndValue = !!(key && key.startsWith('assets.') && value);
		const validDomainSuffixes = [
			/\.app\.frontlineeducation\.com$/,
			/\.frontlineeducation\.com$/,
			/\.frontline-ed\.vercel\.app$/,
			/\.frontline-ed\.now\.sh$/,
			/\.cdn1\.frontlineeducation\.com$/,
			/\/\/localhost:\d{4,4}$/
		];
		const valueIsValidUrl = (
			(/^https?:/).test(value)
			&& validDomainSuffixes.some(suffixRe => suffixRe.test(value))
		);
		if (hasKeyAndValue && valueIsValidUrl) {
			window.localStorage.setItem(key, value);
		}
		removeQueryParam('setAsset');
	}
}
