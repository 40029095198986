import assetManifest from '../asset-manifest.json';

export const frameworks = processOverrides('frameworks');
export const uiModules = processOverrides('uiModules');
export const tools = processOverrides('tools');

function processOverrides(type) {
	// spin over the Zeit hosted assets and apply any overrides that are
	// found in localStorage

	const env = window.localStorage.getItem('env') || 'production';

	// The use of release candidate urls is on by default. Should only be off if
	// explicitly set to the value "false". Configurable in Storm.

	const useRcUrls = window.localStorage.getItem('storm.settings.useReleaseCandidates') !== 'false';

	const assetObj = assetManifest[type];
	return Object.keys(assetObj).reduce((acc, k) => {
		const assetOverride = window.localStorage.getItem(`assets.${type}.${k}`);
		if (assetOverride === acc[k]) {
			// if the override value is the same as the original value
			// remove the override
			window.localStorage.removeItem(`assets.${type}.${k}`);
		} else if (assetOverride != null) {
			// if the override value is not null, apply the override
			acc[k] = assetOverride;
		} else if ((env !== 'production' && useRcUrls) || process.env.IDP === 'morpheus') {
			// if the environment is not production (lower environments) we
			// should load all FL CDN assets from their release candidate url.
			// a release candidate url is the lower environment static url
			if (k === 'sidekick') {
				acc[k] = process.env.SIDEKICK_PATH;
			}
			if (k === 'angularjs') {
				acc[k] = process.env.ANGULAR_FW_PATH;
			}
			if (k === 'angularjs18') {
				acc[k] = process.env.ANGULAR18_FW_PATH;
			}
		}
		return acc;
	}, assetObj);
}
