export const ENV_VAR = {
    PRODUCTION: 'production',
    FC_QA: 'fc_qa',
    PG_QA: 'pg_qa',
    FC_DEV: 'fc_dev',
    STAGE: 'stage',
    MORPHEUS: 'morpheus',
    DEFAULT: 'env',
};

export const ENV_URL = {
    SUPERSUITAWSQA: 'supersuitawsqa',
    SUPERSUITAWSDEV: 'supersuitawsdev',
};

export const ERROR_MSG = {
    UNEXP_TOKEN: 'Unexpected token < in JSON at position 0',
    ENV_ERROR: 'ENVIRONMENT CONFIG ERROR',
    APP_ERROR: 'threw up somewhere in app.js',
};
